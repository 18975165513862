import { Checkbox } from 'components/core';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatDate, formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const INVENTORY_REPORT_FIELDS = [
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('sku:stock-number') : 'Stock #',
    key: 'sku',
    id: 'sku',
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Description',
    key: 'description',
    id: 'description',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Location',
    key: 'location_name',
    id: 'location_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product #',
    key: 'product_number',
    id: 'product_number',
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Name',
    key: 'vendor_name',
    id: 'vendor_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Code',
    key: 'vendor_code',
    id: 'vendor_code',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'SKU',
    key: 'product_sku',
    id: 'product_sku',
    sortable: true,
    filterable: true,
  },
  {
    name: 'UPC',
    key: 'upc',
    id: 'upc',
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category')
      ? i18n.t('sku:stock-category-code')
      : 'Stock Category Code',
    key: 'sku_category_code',
    id: 'sku_category_code',
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category') ? i18n.t('sku:stock-category') : 'Stock Category',
    key: 'sku_category',
    id: 'sku_category',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Warranty',
    key: 'warranty',
    id: 'warranty',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Barcode #',
    key: 'barcode_id',
    id: 'barcode_id',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Serial #',
    key: 'serial',
    id: 'serial',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Memo',
    key: 'memo',
    id: 'memo',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Quantity',
    key: 'quantity',
    id: 'quantity',
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity,
    sortable: true,
    isDefault: true,
    filterable: true,
  },
  {
    name: 'Quantity On Hand',
    key: 'quantity_on_hand',
    id: 'quantity_on_hand',
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity_on_hand,
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Quantity On Order',
    key: 'quantity_on_order',
    id: 'quantity_on_order',
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity_on_order,
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Days On Hand',
    key: 'days_on_hand',
    id: 'days_on_hand',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => row.days_on_hand,
  },
  {
    name: 'Retail',
    key: 'retail',
    id: 'retail',
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.retail),
    sortable: true,
    isDefault: true,
    filterable: true,
  },
  {
    name: 'Online Price',
    key: 'online_price',
    id: 'online_price',
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.online_price),
    sortable: true,
    isDefault: true,
    filterable: true,
  },
  {
    name: 'Cost',
    key: 'cost',
    id: 'cost',
    type: 'NUMBER',
    isCurrency: true,
    render: (row: ReportRow) => formatMoney(row.cost),
    sortable: true,
    isDefault: true,
    filterable: true,
  },
  {
    name: 'Ext. Cost',
    key: 'ext_cost',
    id: 'ext_cost',
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_cost),
    sortable: true,
    isDefault: true,
    filterable: true,
  },
  {
    name: 'Wholesale',
    key: 'wholesale',
    id: 'wholesale',
    type: 'NUMBER',
    isCurrency: true,
    render: (row: ReportRow) => formatMoney(row.wholesale),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Ext. Wholesale',
    key: 'ext_wholesale',
    id: 'ext_wholesale',
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_wholesale),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Market Cost',
    key: 'market_cost',
    id: 'market_cost',
    type: 'NUMBER',
    isCurrency: true,
    render: (row: ReportRow) => formatMoney(row.market_cost),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Ext. Market Cost',
    key: 'ext_market_cost',
    id: 'ext_market_cost',
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_market_cost),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Received Date',
    key: 'received_date',
    id: 'received_date',
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.received_date),
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Partners',
    key: 'partners',
    id: 'partners',
    type: 'DATE',
    render: (row: ReportRow) => row.partners && row.partners.map(p => p.name).join(', '),
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Custom 1',
    key: 'custom_1',
    id: 'custom_1',
    render: (row: ReportRow) => row.custom_1,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 2',
    key: 'custom_2',
    id: 'custom_2',
    render: (row: ReportRow) => row.custom_2,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 3',
    key: 'custom_3',
    id: 'custom_3',
    render: (row: ReportRow) => row.custom_3,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 4',
    key: 'custom_4',
    id: 'custom_4',
    render: (row: ReportRow) => row.custom_4,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 5',
    key: 'custom_5',
    id: 'custom_5',
    render: (row: ReportRow) => row.custom_5,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Warning Note',
    key: 'warning_note',
    id: 'warning_note',
    render: (row: ReportRow) => row.warning_note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Tag Description',
    key: 'sku_tag_description',
    id: 'sku_tag_description',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Updated By',
    key: 'sku_last_updated_user',
    id: 'sku_last_updated_user',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Updated',
    key: 'sku_last_updated_date',
    id: 'sku_last_updated_date',
    render: (row: ReportRow) => formatDate(row.sku_last_updated_date),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  // New Fields
  {
    name: 'Product -  Description',
    key: 'product_description',
    id: 'product_description',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product - Tag Description',
    key: 'product_tag_description',
    id: 'product_tag_description',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product - Retail',
    key: 'product_price',
    id: 'product_price',
    render: (row: ReportRow) => formatMoney(row.product_price),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product - Last Updated By',
    key: 'product_last_updated_user',
    id: 'product_last_updated_user',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product - Last Updated',
    key: 'product_last_updated_date',
    id: 'product_last_updated_date',
    render: (row: ReportRow) => formatDate(row.product_last_updated_date),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Received Date',
    key: 'last_received_date',
    id: 'last_received_date',
    render: (row: ReportRow) => formatDate(row.last_received_date),
    type: 'DATE',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Inventory Case',
    key: 'inventory_case',
    id: 'inventory_case',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Open Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    type: 'LINK',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.order_uuid && (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/transactions/${row.order_uuid}`}
        >
          {row.transaction_id}
        </Link>
      ),
  },
  {
    name: 'Image',
    id: 'image',
    key: 'image',
    type: 'IMAGE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.product_image_url && (
        <img
          alt=''
          id='product-image-table'
          src={row.product_image_url}
          style={{ maxHeight: '100px', minHeight: '50px' }}
        />
      ),
  },
  {
    name: 'Margin %',
    key: 'margin',
    id: 'margin',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Master Product #',
    key: 'master_product_number',
    id: 'master_product_number',
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.master_product_uuid}`}
      >
        {row.master_product_number}
      </Link>
    ),
  },
  {
    name: 'Preowned',
    key: 'is_preowned',
    id: 'is_preowned',
    isDefault: false,
    sortable: true,
    render: row => <Checkbox checked={row.is_preowned} disabled />,
  },
];
